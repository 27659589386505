import React, { useState } from 'react';
import './Header.css';

const Header = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      {/* <div className="logo">
        <h1>IF Campinas Participativo e Democrático</h1>
      </div> */}
      <button className="hamburger" onClick={toggleMenu}>
        &#9776;
      </button>
      <nav className={`navbar ${isMenuOpen ? 'open' : ''}`}>
        <ul className="menu">
          <li className="menu-item"><a href="#manifesto">Carta Aberta</a></li>
          <li className="menu-item"><a href="#quemsomos">Quem Somos</a></li>
          <li className="menu-item"><a href="#convite">Nosso Convite</a></li>
          <li className="menu-item"><a href="#suggestion-form">Participe</a></li>
          {/* <li className="menu-item"><a href="#fiquepordentro">Fique Por Dentro</a></li> */}
          <li className="menu-item"><a href="#fiquepordentro">De Olho na Agenda</a></li>
          <li className="menu-item"><a href="https://firebasestorage.googleapis.com/v0/b/ifcmpdemocratico.appspot.com/o/Plano_Gestao.pdf?alt=media&token=040e570f-8c35-4937-ab3c-3dfec7452ec8">Programa de Gestão</a></li>

        </ul>
      </nav>
    </header>
  );
};

export default Header;
