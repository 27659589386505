import React, { useContext, useState } from 'react';
import { CitizensDataContext } from '../../context/CitizensDataContext';
import './JoinManifest.css';

const JoinManifest = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const { addData } = useContext(CitizensDataContext);

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('[Join Manifesta]  adddata');
    if (name && email) {
      addData(name, email);
      setName(''); // Clear the form fields
      setEmail('');
    } else {
      alert('Preencha os dois campos.');
    }
  };

  return (
    <section className='join-manifest-section' id='fiquepordentro' >
      <h2 className='join-manifest-title'>Quer ficar por dentro do que rola no Coletivo Democracia e Participação? </h2>
      <p className='join-manifest-text'>
        É só cadastrar o seu e-mail.
      </p>
      <form className='join-manifest-form' onSubmit={handleSubmit}>
        <div className='form-group'>
          <label htmlFor='name'>Nome</label>
          <input
            type='text'
            id='name'
            name='name'
            className='form-input'
            required
            placeholder='Seu nome'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className='form-group'>
          <label htmlFor='email'>Email</label>
          <input
            type='email'
            id='email'
            name='email'
            className='form-input'
            required
            placeholder='Seu email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <button type='submit' className='submit-button'>
          Cadastrar
        </button>
      </form>
    </section>
  );
};

export default JoinManifest;
