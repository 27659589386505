import './Block4.css';

import punho from '../../assets/images/punho.png';

const Block4 = () => {
  return (
    <section className='block4'>
      <div className='block4-left'>
        <p className='block4-text'>
          <strong>É crucial que continuemos unidos e engajados. </strong>Como
          trabalhadoras e trabalhadores do IFSP Campus Campinas, somos parte
          essencial de uma instituição ancorada em um amplo leque formativo.
          Temos o compromisso de formar, além de profissionais capacitados,
          cidadãos e cidadãs que atuem em um mundo profundamente desigual em
          constante transformação. Como estudantes e educadores, é indispensável
          que nos engajemos ativamente para tornar públicas nossas necessidades
          e convicções.{' '}
          <br /><br />
          <strong>
            Para isso, só vemos um caminho: a construção de uma instituição que
            garanta e promova uma gestão democrática e participativa.
          </strong>
        </p>
      </div>
      <div className='block4-right'>
        <div className='image-container'>
          <img src={punho} alt='Punho' />
        </div>
      </div>
    </section>
  );
};

export default Block4;
