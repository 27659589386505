import React, { useState } from 'react';
import ReactGA from 'react-ga4';

import './Manifest.css';
import imageIf from '../../assets/images/maos.png';

const Manifest = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    ReactGA.event({
      category: 'User', // The category name for the event
      action: 'Clicked Button Carta Aberta', // The specific action
      label: 'Submit Button', // (Optional) a label to describe the event
    });
    setIsExpanded(!isExpanded);
  };

  return (
    <section className='manifest' id='manifesto'>
      <h2 className='manifest-title barrio'>Carta Aberta</h2>
      <div className='manifest-content'>
        <div className='image-container'>
          <img src={imageIf} alt='Imagem do logo do if entre duas mãos' />
        </div>
        <div className='text-container'>
          <p className='manifest-text'>
            A greve de modo geral, em especial no Campus Campinas, evidenciou um
            período desafiador, marcado pela união e determinação por melhores
            condições para o pleno desenvolvimento daquilo que sabemos fazer de
            melhor:
            <strong>
              ensinar, pesquisar e promover a ciência e o diálogo com a
              comunidade por meio das ações extensionistas.
            </strong>{' '}
            A greve que enfrentamos demonstrou o potencial da nossa força
            coletiva e a constante preocupação com uma educação de qualidade
            socialmente referenciada. É crucial que continuemos unidos e
            engajados. Como trabalhadoras e trabalhadores do IFSP Campus
            Campinas, somos parte essencial de uma instituição ancorada em um
            amplo leque formativo. Temos o compromisso de formar, além de
            profissionais capacitados, cidadãos e cidadãs que atuem em um mundo
            profundamente desigual em constante transformação.
          </p>
          {!isExpanded && <span className='dots'>...</span>}
          {isExpanded && (
            <>
              <p className='manifest-text'>
                Como estudantes e educadores, é indispensável que nos engajemos
                ativamente para tornar públicas nossas necessidades e
                convicções.Para isso, só vemos um caminho: a construção de uma
                instituição que garanta e promova uma gestão democrática e
                participativa.
              </p>
              <p className='manifest-text'>
                Por isso, convidamos todas e todos para se juntarem a um
                movimento de reflexão e ação. Partimos de uma realidade em que
                momentos de diálogo e deliberações coletivas são inexistentes em
                nosso campus. Logo, queremos criar espaços de discussão e
                debate, onde possamos pensar os desafios e oportunidades que
                enfrentamos em nossa jornada educacional.
              </p>
              <p className='manifest-text'>
                O objetivo deste movimento é acolher estudantes, servidores e
                terceirizados que desejam contribuir para o aprimoramento
                contínuo de nossa escola, numa perspectiva de "esperançar", se
                afastando de um sentimento passivo de espera, e se orientando
                por uma intenção concreta e consciente de transformação da
                realidade. Trata-se da crença de que podemos e devemos construir
                um futuro melhor através da educação e da participação ativa,
                afinal esta escola pertence a todas e a todos nós.
              </p>
              <p className='manifest-text'>
                Somos um grupo de servidores, servidoras, alunas e alunos que se
                pauta pela Educação profissional e Tecnológica de Qualidade,
                defendendo uma escola inclusiva e participativa assentada nos
                princípios da educação profissional e tecnológica de qualidade,
                no tripé Ensino-Pesquisa-Extensão e na missão do IFSP. Somos um
                grupo que busca a concretização da ideia de uma instituição de
                ensino que não se limite à preparação de profissionais para o
                mercado de trabalho, mas que transforme alunas e alunos em
                cidadãos e cidadãs que possam tocar e transformar o mundo
                através de suas atuações.
              </p>
              <p className='manifest-text'>
                Pautamo-nos, também, por uma gestão verdadeiramente democrática,
                transparente e participativa, em que todos os membros da
                comunidade tenham vez e voz, e se sintam representados nas
                decisões institucionais. É através da inclusão e da participação
                de todos que queremos buscar a melhoria da gestão do campus e a
                valorização dos nossos servidores e servidoras.
              </p>
              <p className='manifest-text'>
                Este é um convite para que cada um de nós se envolva ativamente
                nesse movimento. Queremos ouvir suas ideias, suas preocupações e
                suas propostas. E se o IFSP-Campinas fosse como queremos?
                Assim... no plural, no coletivo!
              </p>
              <p className='manifest-text'>
                Juntos, podemos construir um ambiente acadêmico mais justo,
                inclusivo e dinâmico. Contamos com a participação de todos e
                todas para que deem suas contribuições nesta jornada rumo a um
                campus mais forte e comprometido com a educação que transforma
                vidas. Venha participar da nossa reunião presencial no dia 04 no
                pátio e/ou contribua com a sua opinião e sugestões no nosso
                formulário on-line abaixo no site. Podemos, juntos, fazer do
                IFSP-Campinas um exemplo de gestão democrática e excelência
                educacional. 
                
                {/* Sugestão para o verso: Nosso movimento tem dois
                pilares fundamentais: 1. Educação Profissional e Tecnológica de
                Qualidade: Comprometemo-nos a repensar e aprimorar continuamente
                nossos métodos educacionais, garantindo a preparação dos
                estudantes não apenas para o mercado de trabalho, mas para serem
                agentes de transformação social em suas comunidades. 2. Gestão
                Democrática e Participativa: Defendemos uma administração séria,
                transparente, inclusiva e participativa, em que todos os membros
                da comunidade acadêmica tenham voz nas decisões que impactam
                suas vidas e seus trabalhos, com a valorização contínua dos
                servidores e servidoras que garantem essa escola de qualidade. */}
                </p>
                <h2 style={{textAlign: 'center', color: 'white'}}>Coletivo Democracia e Participação !!!</h2>
            </>
          )}
          <button className='read-more-button' onClick={toggleReadMore}>
            {isExpanded ? 'Menos' : 'Leia mais'}
          </button>
        </div>
      </div>
    </section>
  );
};

export default Manifest;
