import React from 'react';
import { FaBook } from "react-icons/fa";
import './Block8.css';

const Block8 = () => {
  return (
    <section className='block8' id='programagestao'>
      <FaBook color={'#660034'} style={{ margin: '20px', fontFamily: 'Barrio'}}/>
      <a href="https://firebasestorage.googleapis.com/v0/b/ifcmpdemocratico.appspot.com/o/Plano_Gestao.pdf?alt=media&token=040e570f-8c35-4937-ab3c-3dfec7452ec8" >Programa de Gestão</a>
      <p style={{textAlign: 'center'}}>As sugestões dadas pelo site foram incorporadas ao plano de gestão, mas você ainda pode continuar enviando sugestões.</p>
    </section>
  );
};

export default Block8;
