import React from 'react';
import './Agenda.css';
import olho from '../../assets/images/2.png';
import qrcodedebate from '../../assets/images/qrcodeperguntas.png';

const Agenda = () => {
  return (
    <section className='agenda' id='agenda'>
      <div className='agenda-left'>
        <p className='barrio f50'>De olho na agenda</p>
        <div className='image-container'>
          <img src={olho} alt='olho' />
        </div>
      </div>
      <div className='agenda-right'>
        <h3 className='agenda-title'>Próximos eventos</h3>
        <ul className='agenda-list'>
        <li>
            <span className='agenda-date'>10-Out 18:30hs:</span> 2º Debate entre os candidatos
          </li>
          <li>
            <span className='agenda-date'>Local:</span> Auditório
            <br />
            <p>Envie suas perguntas</p>
            <img src={qrcodedebate} alt='QR code das perguntas' style={{width: '100px'}}/>
          </li>
          <li>
            <span className='agenda-date'>02-Out 11:30hs:</span> 1º Debate entre os candidatos
          </li>
          <li>
            <span className='agenda-date'>Local:</span> Auditório
            <br />
          </li>
          <li>
            <span className='agenda-date'>25-Set 12hs:</span> Lançamento da
            candidatura do coletivo
          </li>
          <li>
            <span className='agenda-date'>Local:</span> Pátio
          </li>
          <li>
            <span className='agenda-date'>24-Set 18hs30:</span> Reunião DCE com
            o coletivo
          </li>
          <li>
            <span className='agenda-date'>Local:</span> Pátio
          </li>
          <li>
            <span className='agenda-date'>11-Set:</span> Reunião para
            apresentação e discussão da carta aberta
          </li>
          <li>
            <span className='agenda-date'>Local:</span> Pátio
          </li>
          <li>
            <span className='agenda-date'>04-Set:</span> Reunião para
            apresentação e discussão da carta aberta
          </li>
          <li>
            <span className='agenda-date'>Local:</span> Pátio
          </li>
          {/* Adicione mais eventos aqui se necessário */}
        </ul>
      </div>
    </section>
  );
};

export default Agenda;
