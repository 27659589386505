import React, { useState, useContext } from 'react';
import { DataContext } from '../../context/SuggestionsDataContext';
import './SuggestionForm.css';
import escrita from '../../assets/images/escreve.png';

const SuggestionForm = () => {
  const [name, setName] = useState('');
  const [suggestion, setSuggestion] = useState('');

  const { addData, data } = useContext(DataContext);

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('[Suggestion Form]  adddata');
    if (suggestion) {
      addData(name, suggestion);
      setName(''); // Clear the form fields
      setSuggestion('');
    } else {
      alert('Preencha os dois campos.');
    }
  };

  return (
    <section className="suggestion-form" id='suggestion-form'>
      <div className='suggestion-form-left'>
        <div className='image-container'>
          <img src={escrita} alt='escrita' />
        </div>
      </div>
      <div className='suggestion-form-right'>
        <h1 className="barrio text-white">Faça sugestões</h1>
        <h3>Qual proposta você teria para o nosso IF?</h3>
        <h2 className='barrio text-white f50'>Participe</h2>
        <form className="form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Nome (Opcional)</label>
            <input
              type="text"
              id="name"
              name="name"
              className="form-input"
              placeholder="Seu nome"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="suggestion">Sugestão</label>
            <textarea
              id="suggestion"
              name="suggestion"
              className="form-input"
              placeholder="Digite sua sugestão aqui..."
              rows="5"
              required
              value={suggestion}
              onChange={(e) => setSuggestion(e.target.value)}
            />
          </div>
          <button type="submit" className="submit-button">Enviar Sugestão</button>
          <h3 className='text-center'>Já temos {data.length} sugestões</h3>
        </form>
      </div>


    </section>
  );
};

export default SuggestionForm;
