import React from 'react';
import './Footer.css';
import { FaInstagram } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="footer">
      <div className='footer-line1'>
        <div className='footer-left'>
          <p className='barrio' style={{ fontSize: '25px' }}>Bora conversar</p>
        </div>
        <div className='footer-right'>
          <div className="social-icons">
            <a href="https://www.instagram.com/seuperfil" target="_blank" rel="noopener noreferrer" className="social-link">
              <FaInstagram color={'#135736'} size={'40px'}/>
            </a>
            <a href="mailto:email@example.com" className="social-link">
              {/* <FaEnvelope /> */}
              <p className='secondary-color barrio'>E-Mail</p>
              <p className='quaternary-color'>ifdemocratico@gmail.com</p>
            </a>
          </div>
        </div>
      </div>
      <div className='footer-line2'>
        <p>&copy; 2024 Coletivo Democracia e Participação.</p>
        <p>
          Este trabalho está licenciado sob uma {' '}
          <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/" target="_blank" rel="noopener noreferrer" className="cc-link">
            Licença Creative Commons Atribuição-NãoComercial-CompartilhaIgual 4.0 Internacional
          </a>.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
