// firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCs0G8QRpVWJTjevYe5GpBFbGhVhftKFEM",
  authDomain: "ifcmpdemocratico.firebaseapp.com",
  projectId: "ifcmpdemocratico",
  storageBucket: "ifcmpdemocratico.appspot.com",
  messagingSenderId: "782854420592",
  appId: "1:782854420592:web:fdf031ac59118152771cb5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export { db };
