import './Banner.css';
import roda from '../../assets/images/roda.png';

const Banner = () => {
  return (
    <section className='banner'>
      <div className='banner-left'>
        <div className='banner-title'>
          <p className='barrio secondary-color'>coletivo</p>
          <p className='barrio secondary-color'>Democracia e</p>
          {/* <p className='barrio secondary-color'>E</p> */}
          <p className='barrio secondary-color'>Participação</p>
        </div>
        <section className='quaternary-color' style={{fontSize: '30px'}}>
          <p className='quaternary-color'>E se o IFSP-Campinas</p>
          <p>fosse como queremos?</p>
          <p>Assim... <span className='secondary-color'>no plural,</span> no coletivo! O que você faria?</p>
        </section>
      </div>
      <div className='banner-right'>
        <div className='image-container'>
          <img className='roda' src={roda} alt='Megafone' />
        </div>
      </div>
    </section>
  );
};

export default Banner;
