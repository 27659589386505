// DataContext.js
import React, { createContext, useState, useEffect } from 'react';
import { collection, getDocs, addDoc, Timestamp } from 'firebase/firestore';
import { db } from '../firebase';

// Create a Context
const CitizensDataContext = createContext();

// Create a Provider Component
const CitizensDataProvider = ({ children }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'citizens'));
        const dataList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setData(dataList);
        console.log(data);
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    fetchData();
  }, []);

  const addData = async (name, email) => {
    try {
      const docRef = await addDoc(collection(db, 'citizens'), {
        name,
        email,
        created_at: Timestamp.fromDate(new Date()),
        modified_at: Timestamp.fromDate(new Date()),
      });
      console.log('Document written with ID: ', docRef.id);
      setData([
        ...data,
        {
          id: docRef.id,
          name,
          email,
          created_at: Timestamp.fromDate(new Date()),
          modified_at: Timestamp.fromDate(new Date()),
        },
      ]);
      alert('Dados Cadastrados com Sucesso !!!')
    } catch (error) {
      console.error('Error adding document: ', error);
    }
  };

  return (
    <CitizensDataContext.Provider value={{ data, addData }}>
      {children}
    </CitizensDataContext.Provider>
  );
};

export { CitizensDataProvider, CitizensDataContext };
