import './Block5.css';

import coracao from '../../assets/images/coracao.png';

const Block5 = () => {
  return (
    <section className='block5'>
      <div className='section-1'>
        <div className='block5-left'>
          <div className='image-container'>
            <img src={coracao} alt='Coração' />
          </div>
        </div>
        <div className='block5-right text-center'>
          <p className='text-white' style={{fontSize: '25px', lineHeight: 1.5}}>
            Por isso, convidamos todas e todos para se juntarem a um movimento
            de reflexão e ação. Partimos de uma realidade onde momentos de
            diálogo e deliberações coletivas são inexistentes em nosso campus.
            Logo, queremos criar espaços de discussão e debate, onde possamos
            pensar os desafios e oportunidades que enfrentamos em nossa jornada
            educacional.
          </p>
        </div>
      </div>
      <div className='section-2 text-center'>
        <p className='barrio text-white' style={{fontSize: '30px'}}>
          O objetivo deste movimento é acolher estudantes, servidores e
          terceirizados que desejam contribuir para o aprimoramento contínuo de
          nossa escola, numa perspectiva de "esperançar", se afastando de um
          sentimento passivo de espera, e se orientando por uma intenção
          concreta e consciente de transformação da realidade.{' '}
        </p>
      </div>
    </section>
  );
};

export default Block5;
