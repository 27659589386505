import './Block6.css';

import exclamacao from '../../assets/images/exclamacao.png';

const Block6 = () => {
  return (
    <section className='block6' id='quemsomos'>
      <div className='block6-left'>
        <h2>Quem somos</h2>
        <p className='secondary-color' style={{fontSize: '25px'}}>
          Somos um grupo de servidores, servidoras, alunas e alunos que defendem
          uma escola inclusiva e participativa assentada nos princípios da
          educação profissional e tecnológica de qualidade, no tripé
          Ensino-Pesquisa-Extensão. Um grupo que busca a concretização da ideia
          de uma instituição de ensino que não se limite à preparação de
          profissionais para o mercado de trabalho, mas que transforme alunas e
          alunos em cidadãos e cidadãs que possam tocar e transformar o mundo
          através de suas atuações social, cultural e econômica por meio da sua
          profissão. Nos pautamos, também, por uma gestão verdadeiramente
          democrática, transparente e participativa, em que todos os membros da
          comunidade tenham vez e voz, e se sintam representados nas decisões
          institucionais.
        </p>
        <p style={{ color: 'black', fontSize: '24px', margin: '10px' }}>
          É através da inclusão e da participação de todos que queremos buscar a
          melhoria da gestão do campus e a valorização dos nossos servidores e
          servidoras!
        </p>
      </div>
      <div className='block6-right'>
        <div className='image-container'>
          <img src={exclamacao} alt='Exclamação' />
        </div>
      </div>
    </section>
  );
};

export default Block6;
