import React from 'react';
import './Block7.css';
import flor from '../../assets/images/14.png';

const Block7 = () => {
  return (
    <section className='block7' id='convite'>
      <div className='block7-left'>
        <div className='image-container'>
          <img src={flor} alt='flor' />
        </div>
      </div>
      <div className='block7-right'>
        <h2 style={{color: 'white'}}>Nosso Convite</h2>

        <p className='text-white' style={{ fontSize: '25px', lineHeight: 1.5 }}>
          Este é um convite para que cada um de nós se envolva ativamente nesse
          movimento. Queremos ouvir suas ideias, suas preocupações e suas
          propostas. Contamos com a participação de todos e todas para que deem
          suas contribuições nesta jornada rumo a um campus mais forte e
          comprometido com a educação que transforma vidas. Juntos, podemos
          fazer do IFSP-Campinas um exemplo de gestão democrática e excelência
          educacional.
        </p>
      </div>
    </section>
  );
};

export default Block7;
