import { CitizensDataProvider } from './context/CitizensDataContext';
import { DataSuggestionProvider } from './context/SuggestionsDataContext';
import { NewsDataProvider } from './context/NewsDataContext';
import ReactGA from 'react-ga4';
import Layout from './Layout';
import './App.css';

function App() {
  ReactGA.initialize('G-3B8ZLBQWKS');
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname + window.location.search,
  });

  return (
    <div className='App'>
      <CitizensDataProvider>
        <DataSuggestionProvider>
          <NewsDataProvider>
            <Layout />
          </NewsDataProvider>
        </DataSuggestionProvider>
      </CitizensDataProvider>
    </div>
  );
}

export default App;
