import React from 'react';
import Header from './components/Header/Header';
import Manifest from './components/Manifest/Manifest';
// import News from './components/News/News';
import JoinManifest from './components/JoinManifest/JoinManifest';
import Footer from './components/Footer';
import Banner from './components/Banner/Banner';
import Block4 from './components/Block4/Block4';
import Block5 from './components/Block5/Block5';
import Block6 from './components/Block6/Block6';
import Block7 from './components/Block7/Block7';
import Block8 from './components/Block8/Block8';
import Diretor from './components/Diretor/Diretor'
import Agenda from './components/Agenda/Agenda';
import SuggestionForm from './components/SuggestionForm/SuggestionForm';

const Layout = ({ children }) => {
  return (
    <div>
      <Header />
      <Banner />
      <Diretor />
      <Block8 />
      <Manifest />
      <Block4 />
      <Block5 />
      <Block6 />
      <Block7 />
      <SuggestionForm />
      <JoinManifest />
      <Agenda />
      {/* <News /> */}
      <main>{children}</main>

      <Footer />
    </div>
  );
};

export default Layout;
