import './Diretor.css';
import fotojulio from '../../assets/images/fotojulio.png';
import fotoclewton from '../../assets/images/Clewton.jpeg';
import fotojuliano from '../../assets/images/Juliano.jpeg';

const Diretor = () => {
  return (
    <section className='diretor'>
      <h2 className='diretor-title'>Nosso candidato a diretor</h2>
      <div className='diretor-content'>
        <div className='diretor-left'>
          <div className='image-container'>
            <img className='foto' src={fotojulio} alt='Foto do Júlio' />
          </div>
          <h2 style={{ fontSize: '40px' }}>Júlio Pedroso</h2>
          <h5 style={{ fontSize: '20px', fontStyle: 'italic' }}>
            "Acredito na escola pública de qualidade, universal e
            sócio-referenciada, e que valorizar a diversidade é a chave para
            combater as desigualdades sociais, culturais e étnicas."
          </h5>
        </div>
        <div className='diretor-right'>
          <ul>
            <li>Servidor Público e professor no IFSP Campinas.</li>
            <li>Mais de 6 anos atuando na gestão escolar.</li>
            <li>10 anos de experiência na docência.</li>
            <li>
              Doutor em Desenvolvimento Humano e Tecnologias, Engenheiro de
              Computação e técnico em informática pela ETEC.
            </li>
            <li>41 anos, companheiro da Mari e padrasto da Serena.</li>
            <li>Adora tocar violão e praticar exercícios físicos.</li>
            <li>Membro eleito do Conselho de Campus (Concam).</li>
          </ul>
        </div>
      </div>
      <h2 className='diretor-title'>Nosso diretor educacional</h2>
      <div className='diretor-content'>
        <div className='diretor-left'>
          <div className='image-container'>
            <img className='foto' src={fotoclewton} alt='Foto do Clewton' />
          </div>
          <h2 style={{ fontSize: '40px' }}>Clewton Fonseca</h2>
          <h5 style={{fontSize: '20px', fontStyle: 'italic'}}>
            "Valorizo a educação pública e a missão do IFSP. Acredito no seu poder transformador, não só do indivíduo, mas também da comunidade onde estamos inseridos."
          </h5>
        </div>
        <div className='diretor-right'>
          <ul>
            <li>Servidor Público e professor no IFSP Campinas.</li>
            <li>Mestre em Física.</li>
            <li>17 anos de experiência na docência.</li>
            <li>Experiência na coordenação de cursos.</li>
            <li>
              Está no segundo mandato como conselheiro do Conselho do Campus
              (Concam).
            </li>
          </ul>
        </div>
      </div>
      <h2 className='diretor-title'>Nosso diretor administrativo</h2>
      <div className='diretor-content'>
        <div className='diretor-left'>
          <div className='image-container'>
            <img className='foto' src={fotojuliano} alt='Foto do Juliano' />
          </div>
          <h2 style={{ fontSize: '40px' }}>Juliano Dias</h2>
          <h5 style={{ fontSize: '20px', fontStyle: 'italic' }}>
            "Está sendo uma honra representar o Coletivo Democracia e
            Participação, junto com o Julio para Direção-Geral de nosso Campus.
            O programa de gestão foi construído à muitas mãos e por muitas
            vozes, representa a vontade de muitos servidores e alunos na
            construção de um campus com mais debate, escuta, participação e
            representatividade no rumo que queremos seguir com nosso IFSP
            Campinas."
          </h5>
        </div>
        <div className='diretor-right'>
          <ul>
            <li>
              Servidor Público Técnico-Administrativo, ocupando o cargo de
              Administrador no IFSP Campinas.
            </li>
            <li>
              11 anos no serviço público, atuando na esfera estadual e federal.
            </li>
            <li>
              Exerceu a direção do Núcleo de Finanças da Diretoria de Ensino da
              Região de Sertãozinho por 4 anos e atualmente atua como
              coordenador na Coordenadoria de Manutenção, Almoxarifado e
              Patrimônio de nosso campus
            </li>
            <li>
              Formado em Administração, Biblioteconomia e especialista em Gestão
              Pública
            </li>
            <li>Mestrando em Educação Profissional e Tecnológica</li>
            <li>
              36 anos, natural de Sertãozinho, ingressei como servidor federal
              no Campus Itaquaquecetuba do IFSP em 2021
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Diretor;
